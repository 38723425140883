import request from '@/utils/request'

// 用户信息统计
export function getUserStatistics(data) {
    return request({
        url: '/get_user_statistics',
        method: 'post',
        data,
    })
}

// /查询量表使用统计
export function get_measure_user_statistics(data) {
    return request({
        url: '/get_measure_user_statistics',
        method: 'get',
        params: data,
    })
}


export function getMeasureStatistics(data) {
    return request({
        url: '/get_measure_statistics',
        method: 'post',
        data,
    })
}
